<template>
  <div class="about">
    <div class="updown_btn">
      <van-cell
        title="一键全部上架"
        label="将联盟市场中的全部商品上架到店铺"
        :border="false"
        center
      >
        <template #right-icon>
          <van-button
            type="danger"
            plain
            size="mini"
            round
            @click="allup(1)"
          >
            一键上架
          </van-button>
        </template>
      </van-cell>
      <van-cell
        title="一键全部下架"
        label="将店铺中的全部商品下架"
        :border="false"
        center
      >
        <template #right-icon>
          <van-button
            type="danger"
            plain
            size="mini"
            round
            @click="alldown()"
          >
            一键下架
          </van-button>
        </template>
      </van-cell>
    </div>
    <div class="search_main">
      <van-sticky :offset-top="0">
        <van-search
          v-model="keywords"
          placeholder="请输入搜索关键词"
          show-action
          shape="round"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch" style="padding: 0 10px;">搜索</div>
          </template>
        </van-search>
      </van-sticky>
      <div class="shaixuan">
        <!-- <van-dropdown-menu>
          <van-dropdown-item @change="descChange" v-model="desc" :options="descArr" />
        </van-dropdown-menu> -->
        <van-grid :column-num="3" :border="false">
          <van-grid-item @click="sortList(0)">
            <template #text>
              <div :class="sort == 1 ? 'sort_list red' : 'sort_list'">新品</div>
            </template>
          </van-grid-item>
          <van-grid-item @click="sortList(1)">
            <template #text>
              <div class="sort_list">
                <span :class="sort == 2 || sort == 3 ? 'red' : ''">销量</span>
                <span class="sort_asc">
                  <van-icon :class="sort == 2 ? 'red' : ''" name="arrow-up" />
                  <van-icon :class="sort == 3 ? 'red' : ''" name="arrow-down" />
                </span>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item @click="sortList(2)">
            <template #text>
              <div class="sort_list">
                <span :class="sort == 4 || sort == 5 ? 'red' : ''">价格</span>
                <span class="sort_asc">
                  <van-icon :class="sort == 4 ? 'red' : ''" name="arrow-up" />
                  <van-icon :class="sort == 5 ? 'red' : ''" name="arrow-down" />
                </span>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <div class="man_list">
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="onLoad"
        >
          <van-cell
            v-for="(item, index) in list"
            clickable
            :key="index"
            @click="toggle(index)"
          >
            <template #icon>
              <van-checkbox :name="item" checked-color="#ee0a24" ref="checkboxes" />
            </template>
            <template #title>
              <van-image
                width="80"
                height="80"
                lazy-load
                :src="item.thumb"
                fit="cover"
              >
                <van-tag
                  mark
                  :type="item.mid > 0 ? 'primary' : 'danger'"
                  size="mini"
                >
                  {{item.mid > 0 ? "上架" : "下架"}}
                </van-tag>
              </van-image>
            </template>
            <template #default>
              <div class="van-ellipsis">
                {{item.title}}
              </div>
              <div class="list_content">
                <div class="list_text">
                  <div class="list_text_attr van-ellipsis">{{item.shorttitle}}</div>
                  <div class="list_price">
                    <span class="price_pref">&yen;</span>
                    <span class="price_num">{{item.price}}</span>
                    <van-tag type="danger">最高可赚</van-tag>
                    <van-tag type="danger" plain>&yen;{{item.commission1_pay}}</van-tag>
                  </div>
                </div>
                <div class="list_btn">
                  <van-button
                    @click.stop="goodsup(index)"
                    size="mini"
                    :type="item.mid > 0 ? 'info' : 'danger'"
                    round
                  >
                    {{item.mid > 0 ? "下架" : "上架"}}
                  </van-button>
                </div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-checkbox-group>
      <van-submit-bar button-text="批量上架" @submit="onSubmit" :safe-area-inset-bottom="true">
        <van-checkbox
          v-model="checked"
          checked-color="#ee0a24"
          ref="checkedallbtn"
          @click="checkAll"
        >
          全选
        </van-checkbox>
      </van-submit-bar>
    </div>
    <van-popup v-model="loadingshow" round :close-on-click-overlay="false">
      <div class="loading">
        <van-loading v-show="!shangjia" size="24px" vertical>上架中...</van-loading>
        <div v-show="shangjia">
          <van-icon name="checked" color="#07C160" size="24px" />
          <div>上架完成</div>
        </div>
        <div style="margin-top: 20px">
          <van-button block round @click="hideloading" :disabled="!shangjia" color="linear-gradient(to right, #ff6034, #ee0a24)">
            确定
          </van-button>
        </div>
      </div>
    </van-popup>
    <van-dialog v-model="goodsDownShow" title="下架时是否保留设置价格？" show-cancel-button :show-confirm-button="false" cancel-button-text="关闭">
      <div style="margin: 10px 20px;">
        <van-grid :column-num="2" :border="false">
          <van-grid-item>
            <van-button round type="primary" size="small" @click="downconfirm(1)">下架并保留价格</van-button>
          </van-grid-item>
          <van-grid-item>
            <van-button round type="warning" size="small" @click="downconfirm(2)">下架并清除价格</van-button>
          </van-grid-item>
        </van-grid>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      keywords: "", // 搜索关键词
      desc: 1, // 排序
      descArr: [
        {text: "综合", value: 1},
        {text: "销量从低到高", value: 2},
        {text: "销量从高到低", value: 3},
        {text: "价格从低到高", value: 4},
        {text: "价格从高到低", value: 5},
        {text: "发布时间从远到近", value: 6},
        {text: "发布时间从近到远", value: 7},
      ], // 排序数组
      sort: 1,
      filteres: 1, // 筛选
      filteresArr: [
        {text: "筛选", value: 1},
      ], // 筛选数组
      result: [], // 已选数据
      list: [], // 数据列表
      loading: false,
      finished: false,
      checked: false,
      currOffset: 0,
      currSize: 10,
      info: {},
      loadingshow: false,
      shangjia: false,
      goodsDownShow: false,
    }
  },
  mounted() {
    this.getinfo();
  },
  activated() {
    this.currOffset = 0;
    this.onLoad();
  },
  methods: {
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onLoad() {
      console.log("ddd");
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/get_list",
          that.$qs.stringify({
            offset: that.currOffset,
            size: that.currSize,
            keywords: that.keywords,
            desc: that.desc,
            filteres: that.filteres
          })
        )
        .then(res => {
          console.log(res);
          that.loading = false;
          if (res.data.code == 100000) {
            if (res.data.data.length >= that.currSize) {
              that.finished = false;
              if (that.currOffset == 0) {
                that.list = res.data.data;
              }else {
                res.data.data.forEach(item => {
                  that.list.push(item);
                })
              }
              that.currOffset += that.currSize;
            }else {
              that.finished = true;
              if (that.currOffset == 0) {
                that.list = res.data.data;
              }else {
                res.data.data.forEach(item => {
                  that.list.push(item);
                })
              }
              that.currOffset += res.data.data.length;
            }
          }else {
            that.finished = true;
          }
        })
        .catch(err => {
          console.log(err);
          that.finished = false;
          that.finished = true;
        })
    },
    // 排序方法
    descChange(id) {
      this.desc = id;
      this.currOffset = 0;
      this.onLoad();
    },

    // 点击排序按钮
    sortList(num) {
      if (num == 0) {
        this.sort = 2;
      }else if(num == 1) {
        if (this.sort == 2) {
          this.sort = 3;
        }else{
          this.sort = 2;
        }
      }else if(num == 2) {
        if (this.sort == 4) {
          this.sort = 5
        }else {
          this.sort = 4
        }
      }
      this.desc = this.sort;
      this.currOffset = 0;
      this.onLoad();
      console.log(num, this.sort, 'ddd');
    },
    // 批量上架
    onSubmit() {
      let that = null;
      that = this;
      const gids = [];
      that.result.forEach(item => {
        gids.push(item.id);
      })
      if (gids.length <= 0) {
        that.$toast('请选择商品');
        return false;
      }
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/beth_set_status",
          that.$qs.stringify({
            gids: gids
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            console.log(that.list, gids);
            that.$toast(res.data.msg ? res.data.msg : "批量上架成功");
            that.$router.go(0);
          }else {
            that.$toast(res.data.msg ? res.data.msg : "请选择商品");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 单选
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
      console.log(this.result.length, this.list.length);
      if (this.result.length == this.list.length-1) {
        this.$refs.checkedallbtn.toggle(true);
      }
    },
    // 全选
    checkAll() {
      if (this.result.length < this.list.length) {
        this.$refs.checkboxGroup.toggleAll(true);
      }else {
        this.$refs.checkboxGroup.toggleAll();
      }
      console.log(this.result, this.list);
    },
    // 搜索
    onSearch() {
      console.log("search");
      this.currOffset = 0;
      this.onLoad();
    },
    // 上下架按钮
    goodsup(index) {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/set_status",
          that.$qs.stringify({
            gid: that.list[index].id
          })
        )
        .then(res => {
          console.log(res);
          let str = "";
          if (that.list[index].mid && that.list[index].mid > 0) {
            str = "下架";
          }else {
            str = "上架";
          }
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : str + "成功");
            // that.list[index].mid = res.data.data;
            if (!that.list[index].mid || that.list[index].mid <= 0) {
              that.list.splice(index, 1);
            }
          }else {
            that.$toast(res.data.msg ? res.data.msg : str + "失败");
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    alldown(){
      this.goodsDownShow = true;
    },
    downconfirm(isclear){
      let that = null;
      that = this;
      let message = '确定要将全部商品下架吗？';
      if(isclear == 1){
        message = '确定要将全部商品下架并保留设置价格吗？';
      }else if(isclear == 2){
        message = '确定要将全部商品下架并清除设置价格吗？';
      }
      that.$dialog.confirm({
        title: '确定全部下架？',
        message: message,
      })
      .then(() => {
        // on confirm
        this.allup(2,isclear);
      })
      .catch(() => {
        // on cancel
      });
      
    },
    // 一键全部上架
    allup(num,isclear=0) {
      let that = null;
      that = this;
      that.info.isallgoods = num;
      if (that.info.isallgoods==1) {
        that.loadingshow = true,
        that.shangjia = false;
      }

      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/set_allgoods_up",
          that.$qs.stringify({
            type: that.info.isallgoods,
            isclear: isclear
          })
        )
        .then(res => {
          console.log(res);
          let str = "";
          let isallgoods = 0;
          if (that.info.isallgoods==1) {
            str = "全部上架";
            isallgoods = 2;
            
          }else {
            str = "全部下架";
            isallgoods = 1;
          }
          if (res.data.code == 100000) {
            that.info.isallgoods = isallgoods
            that.$toast(res.data.msg ? res.data.msg : str + "成功");
            if (isallgoods == 2) {
              that.shangjia = true;
            }else {
              that.$router.go(0);
            }
          }else {
            that.$toast(res.data.msg ? res.data.msg : str + "失败");
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    hideloading() {
      if (!this.shangjia) {
        this.$toast("正在上架中");
        return false;
      }
      this.loadingshow = false;
      this.goodsDownShow = false;
      this.$router.go(0);
    }
  },
}
</script>
<style lang="less">
.about {
  width: 100%;
  min-height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  &:last-child {
    padding-bottom: 100px;
  }
  // 上下架按钮
  .updown_btn {
    overflow: hidden;
    margin-bottom: 10px;
    .van-cell {
      .van-cell__title {
        flex: 1;
        text-align: left;
        font-weight: 450;
      }
      .van-button--mini {
        padding: 0px 15px;
        height: 26px;
        line-height: 26px;
      }
      .van-cell__label {
        margin-top: 0;
      }
    } 
  }
  .search_main {
    overflow: hidden;
    margin-bottom: 10px;
    .van-search {
      .van-cell__value {
        flex: 1;
      }
    }
    .shaixuan {
      // .van-dropdown-menu__bar {
      //   height: 40px;
      // }
      padding: 0 10px;
      height: 40px;
      line-height: 40px;
      overflow: hidden;
      background-color: #fff;
      .van-grid-item__text{
        font-size: 14px;
      }
      .van-grid-item__content {
        padding: 10px 8px;
      }
      .sort_list{
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        .sort_asc{
          display: inline-block;
          overflow: hidden;
          vertical-align: middle;
          height: 10px;
          line-height: 4px;
          width: 12px;
          .van-icon{
            font-size: 8px;
            line-height: 4px;
          }
        }
      }
    }
  }
  .man_list {
    overflow: hidden;
    .van-cell {
      overflow: hidden;
      .van-cell__title {
        padding: 0 10px;
        overflow: hidden;
        min-width: 80px;
        .van-image {
          border-radius: 6px;
          overflow: hidden;
          display: block;
          .van-tag {
            position: absolute;
            left: 0px;
            top: 5px;
          }
        }
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        overflow: hidden;
        color: #333;
        .list_content {
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-items: center;
          .list_text {
            flex: 1;
            overflow: hidden;
            .list_text_attr {
              color: #999;
              line-height: 20px;
              font-size: 12px;
            }
            .list_price {
              font-size: 12px;
              .price_num {
                font-size: 14px;
                padding: 0 5px 0 1px;
              }
              .van-tag--danger {
                border-radius: 2px 0 0 2px;
              }
              .van-tag--plain {
                border-radius: 0 2px 2px 0;
              }
            }
          }
          .list_btn {
            padding-left: 10px;
            .van-button {
              height: 20px;
              padding: 0 15px;
            }
          }
        }
      }
    }
    .van-submit-bar {
      bottom: 50px;
      z-index: 9;
      .van-submit-bar__bar {
        justify-content: space-between;
      }
    }
  }
  .red {
    color: #ff0000;
  }
  // 加载中弹框
  .loading {
    width: 240px;
    padding: 20px;
  }
}
</style>
